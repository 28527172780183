export const progressBarPercentages = {
  START:     8,
  VEHICLES:  32,
  DRIVERS:   62,
  DISCOUNTS: 95,
  REVIEW:    100
}

export const ProgressBarStatus = {
  START:     'START',
  DRIVERS:   'DRIVERS',
  VEHICLES:  'VEHICLES',
  DISCOUNTS: 'DISCOUNTS',
  REVIEW:    'REVIEW'
}
