const coverages = [
  {
    "state": "IL",
    "type": "bodily_injury", // it was "coverage"
    "package": "LIABILITY",
    "description": "Bodily Injury",
    "required": "true",
    "limits": [ // it was "values"
      {
        "applies_to": "per_person",
        "amount": 2500000
      }, {
        "applies_to": "per_accident",
        "amount": 5000000
      }
    ],
    "tool_tip_title": "BODILY INJURY LIMITS",
		"tool_tip_html": "Bodily Injury is the coverage associated with paying for the injuries to the other drivers who were involved in your possible future accidents."
  },
  {
    "state": "IL",
    "type": "bodily_injury",
    "package": "GOOD",
    "description": "Bodily Injury",
    "required": "true",
    "limits": [
      {
        "applies_to": "per_person",
        "amount": 2500000
      }, {
        "applies_to": "per_accident",
        "amount": 5000000
      }
    ],
    "tool_tip_title": "BODILY INJURY LIMITS",
		"tool_tip_html": "Bodily Injury is the coverage associated with paying for the injuries to the other drivers who were involved in your possible future accidents."
  },
  {
    "state": "IL",
    "type": "bodily_injury",
    "package": "BETTER",
    "description": "Bodily Injury",
    "required": "true",
    "limits": [
      {
        "applies_to": "per_person",
        "amount": 5000000
      }, {
        "applies_to": "per_accident",
        "amount": 10000000
      }
    ],
    "tool_tip_title": "BODILY INJURY LIMITS",
		"tool_tip_html": "Bodily Injury is the coverage associated with paying for the injuries to the other drivers who were involved in your possible future accidents."
  },
  {
    "state": "IL",
    "type": "property_damage",
    "package": "LIABILITY",
    "description": "Property Damage",
    "required": "true",
    "limits": [
      {
        "applies_to": "per_accident",
        "amount": 2000000
      }
    ],
    "tool_tip_title": "PROPERTY DAMAGE",
		"tool_tip_html": "Provides coverage for property damage for which the insured is legally liable as a result of an auto accident. This coverage is mandatory and is available at varied limits."
  },
  {
    "state": "IL",
    "type": "property_damage",
    "package": "GOOD",
    "description": "Property Damage",
    "required": "true",
    "limits": [
      {
        "applies_to": "per_accident",
        "amount": 2500000
      }
    ],
    "tool_tip_title": "PROPERTY DAMAGE",
		"tool_tip_html": "Provides coverage for property damage for which the insured is legally liable as a result of an auto accident. This coverage is mandatory and is available at varied limits."
  },
  {
    "state": "IL",
    "type": "property_damage",
    "package": "BETTER",
    "description": "Property Damage",
    "required": "true",
    "limits": [
      {
        "applies_to": "per_accident",
        "amount": 5000000
      }
    ],
    "tool_tip_title": "PROPERTY DAMAGE",
		"tool_tip_html": "Provides coverage for property damage for which the insured is legally liable as a result of an auto accident. This coverage is mandatory and is available at varied limits."
  },
  {
    "state": "IL",
    "type": "uninsured_motorist_bodily_injury",
    "package": "LIABILITY",
    "description": "Uninsured Motorist BI",
    "required": "true",
    "limits": [
      {
        "applies_to": "per_person",
        "amount": 2500000
      }, {
        "applies_to": "per_accident",
        "amount": 5000000
      }
    ],
    "tool_tip_title": "UNINSURED MOTORIST BODILY INJURY",
		"tool_tip_html": "Uninsured Motorists Bodily Injury pays for injuries you or your passenger(s) received in a vehicle accident caused by a driver who does not have auto liability coverage."
  },
  {
    "state": "IL",
    "type": "uninsured_motorist_bodily_injury",
    "package": "GOOD",
    "description": "Uninsured Motorist BI",
    "required": "true",
    "limits": [
      {
        "applies_to": "per_person",
        "amount": 2500000
      }, {
        "applies_to": "per_accident",
        "amount":5000000
      }
    ],
    "tool_tip_title": "UNINSURED MOTORIST BODILY INJURY",
		"tool_tip_html": "Uninsured Motorists Bodily Injury pays for injuries you or your passenger(s) received in a vehicle accident caused by a driver who does not have auto liability coverage."
  },
  {
    "state": "IL",
    "type": "uninsured_motorist_bodily_injury",
    "package": "BETTER",
    "description": "Uninsured Motorist BI",
    "required": "true",
    "limits": [
      {
        "applies_to": "per_person",
        "amount": 5000000
      }, {
        "applies_to": "per_accident",
        "amount": 10000000
      }
    ],
    "tool_tip_title": "UNINSURED MOTORIST BODILY INJURY",
		"tool_tip_html": "Uninsured Motorists Bodily Injury pays for injuries you or your passenger(s) received in a vehicle accident caused by a driver who does not have auto liability coverage."
  },
  {
    "state": "IL",
    "type": "medical_payments",
    "package": "GOOD",
    "description": "Medical Payments",
    "required": "true",
    "limits": [
      {
        "applies_to": "per_person",
        "amount": 100000
      }
    ],
    "tool_tip_title": "MEDICAL PAYMENTS",
		"tool_tip_html": "Provides coverage for additional medical expenses incurred as the result of personal injury covering the driver and passengers of an insured auto. This coverage is optional but often selected."
  },
  {
    "state": "IL",
    "type": "medical_payments",
    "package": "BETTER",
    "description": "Medical Payments",
    "required": "true",
    "limits": [
      {
        "applies_to": "per_person",
        "amount": 200000
      }
    ],
    "tool_tip_title": "MEDICAL PAYMENTS",
		"tool_tip_html": "Provides coverage for additional medical expenses incurred as the result of personal injury covering the driver and passengers of an insured auto. This coverage is optional but often selected."
  },
  {
    "state": "IL",
    "type": "otc",
    "package": "BETTER",
    "description": "Comprehensive",
    "required": "true",
    "limits": [
      {
        "applies_to": "deductible",
        "amount": 50000
      }
    ],
    "tool_tip_title": "COMPREHENSION INSURANCE",
		"tool_tip_html": "Comprehensive coverage helps pay damages to the vehicle if it encounters theft, fire, falling objects, vandalism, and more but not car collision."
  },
  {
    "state": "IL",
    "type": "otc",
    "package": "GOOD",
    "description": "Comprehensive",
    "required": "true",
    "limits": [
      {
        "applies_to": "deductible",
        "amount": 100000
      }
    ],
    "tool_tip_title": "COMPREHENSION INSURANCE",
		"tool_tip_html": "Comprehensive coverage helps pay damages to the vehicle if it encounters theft, fire, falling objects, vandalism, and more but not car collision."
  },
  {
    "state": "IL",
    "type": "collision",
    "package": "GOOD",
    "description": "Collision",
    "required": "true",
    "limits": [
      {
        "applies_to": "deductible",
        "amount": 100000
      }
    ],
    "tool_tip_title": "COLLISION INSURANCE",
		"tool_tip_html": "Provides coverage for a loss to an insured auto as a result of a covered loss less applicable deductible. If you have a loan or are financing an auto, financial institution likely requires this coverage."
  },
  {
    "state": "IL",
    "type": "collision",
    "package": "BETTER",
    "description": "Collision",
    "required": "true",
    "limits": [
      {
        "applies_to": "deductible",
        "amount": 50000
      }
    ],
    "tool_tip_title": "COLLISION INSURANCE",
		"tool_tip_html": "Provides coverage for a loss to an insured auto as a result of a covered loss less applicable deductible. If you have a loan or are financing an auto, financial institution likely requires this coverage."
  },
  {
    "state": "IL",
    "type": "rental",
    "package": "GOOD",
    "description": "Rental",
    "required": "true",
    "limits": [
      {
        "applies_to": "per_day",
        "amount": 2000
      }, {
        "applies_to": "maximum",
        "amount": 42000
      }
    ],
    "tool_tip_title": "RENTAL REIMBURSEMENT",
		"tool_tip_html": "Provides reimbursement up to the daily and policy limit for rental expenses incurred for the rental of a substitute auto when the insured vehicle is involved in a covered loss and withdrawn from use over 24 hours."
  },
  {
    "state": "IL",
    "type": "rental",
    "package": "BETTER",
    "description": "Rental",
    "required": "true",
    "limits": [
      {
        "applies_to": "per_day",
        "amount": 2000
      }, {
        "applies_to": "maximum",
        "amount": 42000
      }
    ],
    "tool_tip_title": "RENTAL REIMBURSEMENT",
		"tool_tip_html": "Provides reimbursement up to the daily and policy limit for rental expenses incurred for the rental of a substitute auto when the insured vehicle is involved in a covered loss and withdrawn from use over 24 hours."
  },
  // {
  //   "state": "IL",
  //   "type": "towing",
  //   "package": "GOOD",
  //   "description": "Towing",
  //   "required": "true",
  //   "limits": [
  //     {
  //       "applies_to": "per_disablement",
  //       "amount": 5000
  //     }
  //   ],
  //   "tool_tip_title": "TOWING & LABOR",
  //   "tool_tip_html": "Provides reimbursement up to the applicable limit for towing costs necessitated by the disablement of an insured auto. Up to two (2) tows are allowed per policy period."
  // },
  // {
  //   "state": "IL",
  //   "type": "towing",
  //   "package": "BETTER",
  //   "description": "Towing",
  //   "required": "true",
  //   "limits": [
  //     {
  //       "applies_to": "per_disablement",
  //       "amount": 5000
  //     }
  //   ],
	// 	"tool_tip_title": "TOWING & LABOR",
	// 	"tool_tip_html": "Provides reimbursement up to the applicable limit for towing costs necessitated by the disablement of an insured auto. Up to two (2) tows are allowed per policy period."
  // }
]

export default coverages
